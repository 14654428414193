import { useState, default as React } from 'react';
import { PanelProps, GrafanaTheme2 } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
// import { useStyles2, useTheme2 } from '@grafana/ui';
import { useStyles2 } from '@grafana/ui';

const IMAGE_TYPES = new Map<string, string>([
	['front', 'Vorne'],
	['grid', 'Tastenfeld'],
	['products', 'Produkte'],
	['sides', 'Seite'],
	['trafik', 'Trafik'],
]);

const GAP = '10px';

function imageUrl(imageId: string) {
	return `https://data.thelightpeak.com/assets/${imageId}.jpg`;
}

interface Props extends PanelProps<SimpleOptions> {}

interface Image {
	type: string;
	image: string;
}

interface VendingMachine {
	standortnummer: number;
	city: string;
	postalCode: number;
	street: string;
	images: Image[];
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      position: relative;
			display: flex;
			gap: ${GAP};
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
		vmList: css`
			flex: 0 0 150px;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			gap: 5px;
			box-shadow: ${theme.shadows.z1};
		`,
		vmListItem: css`
			padding: 0.75rem;
			background-color: ${theme.isLight ? '#EEEEEE' : '#333333'};
			cursor: pointer;
			transition: background: 0.25s;

			&:hover {
				background-color: ${theme.isLight ? '#DDDDDD' : '#444444'};
			}

			&.active {
				background-color: ${theme.isLight ? '#CCCCCC' : '#555555'};
			}
		`,
		detailView: css`
			flex: 1 1 auto;
			display: flex;
			gap: ${GAP};
			flex-direction: column;
		`,
		infoView: css`
			display: grid;
			gap: 5px;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
			box-shadow: ${theme.shadows.z1};

			& div {
				padding: 5px;
			}
		`,
		imageSelector: css`
			flex: 0 0 60px;
			display: flex;
			gap: 5px;
			box-shadow: ${theme.shadows.z1};
		`,
		imageButton: css`
			appearance: none;
			border: none;
			background-color: ${theme.isLight ? '#EEEEEE' : '#333333'};
			flex: 1 1 auto;

			&:hover {
				background-color: ${theme.isLight ? '#DDDDDD' : '#444444'};
			}

			&.active {
				background-color: ${theme.isLight ? '#CCCCCC' : '#555555'};
			}
		`,
		imageView: css`
			flex: 1 1 auto;
			display: flex;
			position: relative;
			background-size: contain;
			background-repeat: no-repeat;
			overflow: hidden;

			img {
				max-height: 100%;
				max-width: 100%;
				pointer-events: auto;
			}
		`,
  };
};

export const ImagePanel: React.FC<Props> = ({ options, data, width, height }) => {
  // const theme = useTheme2();
  const styles = useStyles2(getStyles);

	const [activeVm, setActiveVm] = useState<number | null>(null);
	const [selectedImage, setSelectedImage] = useState<number | null>(null);

	const frame = data.series[0];
	const frameLength = frame.length;

	const vmIdField = frame.fields.find((field) => field.name === 'vending_machine_id');
	const standortnummerField = frame.fields.find((field) => field.name === 'standortnummer');
	const cityField = frame.fields.find((field) => field.name === 'city');
	const postalCodeField = frame.fields.find((field) => field.name === 'postal_code');
	const streetField = frame.fields.find((field) => field.name === 'street');
	const typeField = frame.fields.find((field) => field.name === 'type');
	const imageField = frame.fields.find((field) => field.name === 'image');

	for (let field of [vmIdField, standortnummerField, cityField, postalCodeField, streetField, typeField, imageField]) {
		if (!field) {
			return <p style={{ color: 'red' }}>Missing field. Check your query.</p>
		}
	}

	const vms = new Map<number, VendingMachine>();
	for (let i = 0; i < frameLength; ++i) {
		const vmId: number = (vmIdField as any).values[i];
		const type: string = (typeField as any).values[i];
		const image: string = (imageField as any).values[i];

		if (!vms.has(vmId)) {
			vms.set(vmId, {
				standortnummer: (standortnummerField as any).values[i],
				city: (cityField as any).values[i],
				postalCode: (postalCodeField as any).values[i],
				street: (streetField as any).values[i],
				images: [],
			});
		}
		const images = (vms.get(vmId) as VendingMachine).images;
		images.push({ type: type, image: image });
	}

	function handleClick(vmId: number) {
		setActiveVm(vmId);
		setSelectedImage(null);
	}

	const vmListItems = [];
	for (let vmId of vms.keys()) {
		vmListItems.push(<div className={`${cx(styles.vmListItem)}${vmId === activeVm ? ' active' : ''}`} key={vmId} onClick={() => handleClick(vmId)}>
			{ vmId }
		</div>);
	}

	const activeItem = activeVm ? vms.get(activeVm) : null;

	let detailView = null;
	if (activeItem) {
		const infoView = <div className={styles.infoView}>
			<div>Standortnummer: { activeItem.standortnummer }</div>
			<div>City: { activeItem.city }</div>
			<div>Zip code: { activeItem.postalCode }</div>
			<div>Street: { activeItem.street }</div>
		</div>;

		const imageButtons = activeItem.images.map((item, index) => {
			return <button type="button" key={index} className={`${cx(styles.imageButton)}${index === selectedImage ? ' active' : ''}`} onClick={() => setSelectedImage(index)}>
				{ IMAGE_TYPES.get(item.type) }
			</button>;
		});

		let imageView = null;
		if (selectedImage !== null) {
			const image = activeItem.images[selectedImage].image;
			// imageView = <div className={styles.imageView} style={{ backgroundImage: `url(${imageUrl(image)})` }}>
			imageView = <div className={styles.imageView}>
				<img src={imageUrl(image)}></img>
			</div>;
		}

		detailView = <div className={styles.detailView}>
			{ infoView }
			<div className={styles.imageSelector}>
				{ imageButtons }
			</div>
			{ imageView }
		</div>
	}

	// console.log(dataLength);
	// console.log(fields);
	// console.log(vmIdField);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
			<div className={styles.vmList}>
				{vmListItems}
			</div>

			{detailView}

    </div>
  );
};
